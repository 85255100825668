import { EUDCC1, LabelEntry, RecoveryEntry, TestEntry, VaccinationEntry } from '../../generated-files/dgc-combined-schema'
import i18n from 'i18next'
import utils from '../../misc/utils';
import React from 'react';
import { Card } from 'react-bootstrap';
import moment from 'moment'

interface IDataEntry {
    title: string,
    entries: IEntry[]
}

interface IEntry {
    label: string,
    data: string
}


export const ShowCertificateData = (props: any) => {

    const defaultString = '';

    // const countryCodeValueSet = props.valueSetList[Value_Sets.CountryCodes];
    // const vacMedsData = props.valueSetList[Value_Sets.Vaccines];
    //const diseaseAgentsData = props.valueSetList[Value_Sets.DiseaseAgent];
    // const vaccineManufacturers = props.valueSetList[Value_Sets.VaccinesManufacturer];
    // const vaccines = props.valueSetList[Value_Sets.VaccineType];
    // const testManufacturersValueSet = props.valueSetList[Value_Sets.TestManufacturer];
    // const testResultValueSet = props.valueSetList[Value_Sets.TestResult];
    //const testTypeValueSet = props.valueSetList[Value_Sets.TestType];

    const [eudgc, setEudgc] = React.useState<EUDCC1>();
    const [vaccinationSet, setVaccinationSet] = React.useState<VaccinationEntry>();
    const [testSet, setTestSet] = React.useState<TestEntry>();
    const [recoverySet, setRecoverySet] = React.useState<RecoveryEntry>();
    const [personalData, setPersonalData] = React.useState<IDataEntry[]>();
    const [certificationData, setCertificationData] = React.useState<IDataEntry[]>();


    React.useEffect(() => {
        if (props && props.eudgc) {
            setEudgc(props.eudgc);
        }
    }, [props])


    React.useEffect(() => {
        if (eudgc) {
            const vacc: [VaccinationEntry] = eudgc.v as [VaccinationEntry];
            const test: [TestEntry] = eudgc.t as [TestEntry];
            const recovery: [RecoveryEntry] = eudgc.r as [RecoveryEntry];

            setVaccinationSet(vacc ? vacc[0] : undefined);
            setTestSet(test ? test[0] : undefined);
            setRecoverySet(recovery ? recovery[0] : undefined);

            setPersonalData(getPersonalData());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eudgc])

    React.useEffect(() => {
        if (vaccinationSet) {
            setCertificationData(getVaccinationData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vaccinationSet])

    React.useEffect(() => {
        if (testSet) {
            setCertificationData(getTestData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testSet])

    React.useEffect(() => {
        if (recoverySet) {
            setCertificationData(getRecoveryData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recoverySet])


    const getPersonalData = (): IDataEntry[] => {
        const fecha_nac = eudgc?.dob!.split('-');
        let fecha_format_sv = ''
        if (fecha_nac) {
            fecha_format_sv = fecha_nac[2] + '-' + fecha_nac[1] + '-' + fecha_nac[0];
        }
        const personalData: IDataEntry[] = [
            {
                title: i18n.t('translation:personal-data'),
                entries: [
                    { label: "ID", data: eudgc?.dui || defaultString },
                    { label: i18n.t('translation:name'), data: eudgc?.nam?.fn?.toUpperCase() || defaultString },
                    { label: i18n.t('translation:first-name'), data: eudgc?.nam?.gn?.toUpperCase() || defaultString },
                    { label: i18n.t('translation:date-of-birth'), data: fecha_format_sv || defaultString },
                ]
            }
        ]
        return personalData;
    }

    const getVaccinationData = (): IDataEntry[] => {
        const vacunasList: any = eudgc

        const vacunas: any = []
        vacunasList?.vaccines.forEach((item: any, i: Int32Array) => {
            vacunas.push({ label: "Dosis", data: item.dosis_number, key: i })
            vacunas.push({ label: "Producto", data: item.trademark, key: i })
            vacunas.push({ label: "Fabricante", data: item.product, key: i })
            vacunas.push({ label: "Fecha", data: moment(item.datetime).format("DD/MM/YYYY"), key: i })
        })

        return ([
            {
                title: i18n.t('translation:vaccine-data'),
                entries: [
                    { label: i18n.t('translation:disease-agent'), data: "COVID-19" || defaultString },
                    { label: i18n.t('translation:vaccine'), data: eudgc?.label?.vp || defaultString },
                    { label: i18n.t('translation:vac-medical-product'), data: eudgc?.label?.mp || defaultString },
                    { label: i18n.t('translation:vac-marketing-holder'), data: eudgc?.label?.ma || defaultString },
                ]
            },
            {
                title: i18n.t('translation:vaccination-data'),
                entries: vacunas
            },
            {
                title: i18n.t('translation:certificate-data'),
                entries: [
                    { label: i18n.t('translation:vac-country'), data: i18n.t('translation:pdfMemberPlaceholder2') || defaultString },
                    { label: i18n.t('translation:adm'), data: vaccinationSet?.is || defaultString }
                ]
            }
        ]);
    }

    const getTestData = (): IDataEntry[] => {
        const testData: IDataEntry[] = [
            {
                title: i18n.t('translation:test-data'),
                entries: [
                    { label: i18n.t('translation:disease-agent'), data: 'prueba' || defaultString },
                    { label: i18n.t('translation:testType'), data: 'prueba' || defaultString },
                    { label: i18n.t('translation:testName'), data: testSet?.nm || defaultString },
                    { label: i18n.t('translation:testManufacturers'), data: 'prueba' || defaultString }
                ]
            },
            {
                title: i18n.t('translation:test-data'),
                entries: [
                    { label: i18n.t('translation:sampleDateTime'), data: utils.convertDateToOutputFormat(testSet?.sc || '') },
                    // { label: i18n.t('translation:testDateTime'), data: convertDateToOutputFormat(testSet?.dr || defaultString) },
                    { label: i18n.t('translation:testResult'), data: 'prueba' || defaultString },
                    { label: i18n.t('translation:testCenter'), data: testSet?.tc || defaultString }
                ]
            },
            {
                title: i18n.t('translation:certificate-data'),
                entries: [
                    { label: i18n.t('translation:vac-country'), data: 'prueba' || defaultString },
                    { label: i18n.t('translation:adm'), data: testSet?.is || defaultString }
                ]
            }
        ]
        return testData;
    }

    const getRecoveryData = (): IDataEntry[] => {
        const recoveryData: IDataEntry[] = [
            {
                title: i18n.t('translation:recovery-data'),
                entries: [
                    { label: i18n.t('translation:disease-agent'), data: 'COVI' || defaultString },
                    { label: i18n.t('translation:first-positive-test-date'), data: recoverySet?.fr || defaultString },
                    { label: i18n.t('translation:recovery-country'), data: 'prueba' || defaultString },
                ]
            },
            {
                title: i18n.t('translation:certificate-data'),
                entries: [
                    { label: i18n.t('translation:adm'), data: recoverySet?.is || defaultString },
                    { label: i18n.t('translation:valid-from'), data: recoverySet?.df || defaultString },
                    { label: i18n.t('translation:valid-to'), data: recoverySet?.du || defaultString },
                ]
            }
        ]
        return recoveryData;
    }


    const getDataOutputElement = (dataSet: IDataEntry) => {
        return (
            <>
                <React.Fragment key={JSON.stringify(dataSet)}>
                    <div className='pb-3'>
                        <Card.Text className='data-header-title jcc-xs-jcfs-sm text500' >{dataSet.title}</Card.Text>
                        {dataSet.entries.map((entry) => {
                            return entry.data
                                ? <Card.Text key={JSON.stringify(entry)} className='data-label jcc-xs-jcfs-sm mb-2 text300' >{`${entry.label}: ${entry.data}`}</Card.Text>
                                : <React.Fragment key={JSON.stringify(entry)} />
                        })}
                    </div>
                </React.Fragment>
            </>
        )
    }

    return (
        <>
            {personalData && personalData.map(dataset => getDataOutputElement(dataset))}
            {certificationData && certificationData.map(dataset => getDataOutputElement(dataset))}
        </>
    )
}

export default ShowCertificateData
