/*
 * eu-digital-green-certificates/ dgca-issuance-web
 *
 * (C) 2021, T-Systems International GmbH
 *
 * Deutsche Telekom AG and all other contributors /
 * copyright owners license this file to you under the Apache
 * License, Version 2.0 (the "License"); you may not use this
 * file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { Button, Card, Col, Container, Fade, Row, Spinner } from 'react-bootstrap'

import '../i18n';
import { useTranslation } from 'react-i18next';

import QRCode from 'qrcode.react';

import { EUDCC1 } from '../generated-files/dgc-combined-schema';

import ShowCertificateData from './modules/show-certificate-data.component';
import usePdfGenerator from '../misc/usePdfGenerator';
import AppContext from '../misc/appContext';

import { getFotografiaData } from '../misc/useDataVaccinates';

const ShowCertificate = (props: any) => {
    const context = React.useContext(AppContext);
    const { t } = useTranslation();

    const [isInit, setIsInit] = React.useState(false)
    const [pdfIsInit, setPdfIsInit] = React.useState(false)
    const [pdfIsReady, setPdfIsReady] = React.useState(false)
    const [qrIsReady, setQrIsReady] = React.useState(false);
    const [eudgc, setEudgc] = React.useState<EUDCC1>();
    //const [qrCodeValue, setQrCodeValue] = React.useState('');

    const urlCertificateData = `/record/show/data/${eudgc?.dui}/${eudgc?.dob}`;

    const [qrCodeForPDF2, setQrCodeForPDF2] = React.useState<any>(); //tomara el qr generaro por la url
    const [eudgcForPDF, setEudgcForPDF] = React.useState<EUDCC1>();
    const [issuerCountryCodeForPDF, setIssuerCountryCodeForPDF] = React.useState('');
    const [fotografia, setFotografia] = React.useState<any>(null);

    React.useEffect(() => {
        if (context.navigation)
            setIsInit(true);
    }, [context.navigation])

    React.useEffect(() => {
        setEudgc(props.eudgc)
        if (props.eudgc) {
            // setQrCodeValue('asadsadasdasdasd');
            setQrIsReady(true);
            setIssuerCountryCodeForPDF(props.certificado.countryCode);
            setQrCodeForPDF2(document.getElementById('qr-code-pdf2'));
            setEudgcForPDF(props.eudgc);
        } else {
            props.setError({
                error: '',
                message: t('translation:error-patient-data-load'),
                onCancel: context.navigation!.toLanding
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eudgc, isInit])

    //segundo pdf generado
    const pdf2 = usePdfGenerator(qrCodeForPDF2, eudgcForPDF, issuerCountryCodeForPDF, (isInit) => setPdfIsInit(isInit), (isReady) => setPdfIsReady(isReady));

    const handleDonwloadQr = () => {
        // var link = document.createElement('a');
        const name = eudgc?.nam?.gn + ' ' + eudgc?.nam?.fn;
        let filename = name.split(/[ ]+/).join('_')
        // link.download = filename + '.png';
        // // validate if the browser supports the download attribute
        // let hrefBase = window.document.getElementById('qr-code-pdf') as HTMLCanvasElement;
        // if (hrefBase !== null) {
        //     link.href = hrefBase.toDataURL('image/png');
        //     link.click();
        // }

        let canva = window.document.getElementById('qr-code-pdf2') as HTMLCanvasElement
        canva.toBlob(function (blob: any) {
            var link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = filename + '.png';
            //let isString = btoa(blob)
            //isString = "data: image/png" + ";base64," + isString;
            link.click();
            URL.revokeObjectURL(url);
        })
    }

    // //tomamos en que dispositivo se esta usando la app
    const [osCurrent, setOsCurrent] = React.useState(false);
    React.useEffect(() => {
        //window.alert(navigator.userAgent)
        //validamos en que dispositivo estamos
        if (navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)) {
            //window.alert("esta en iphone");

            //validamos en que navegador estamos actualmente
            const chrome = navigator.userAgent.indexOf('CriOS') > -1;
            const firefox = navigator.userAgent.indexOf('FxiOS') > -1
            if (chrome) {
                setOsCurrent(true)
            }
            if (firefox) {
                setOsCurrent(true)
            }
        } else {
            setOsCurrent(false)
        }
    }, [])

    //validara la posicion de la foto dependiendo el dispositivo se vea la pagina
    const [isMovile, setIsMovile] = React.useState(false);
    React.useEffect(() => {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/OS/i)
        ) {
            setIsMovile(true)
        } else {
            setIsMovile(false)
        }
    }, [])

    const handleShowPdf2 = () => {//funcion para descargar el segundo pdf
        if (pdf2) {
            const blobPDF = new Blob([pdf2.output('blob')], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blobPDF);
            let tempLink = document.createElement('a');
            const filename = eudgc?.dui + '.pdf';
            /*tempLink.href = blobUrl;
            tempLink.setAttribute('download', 'Certificate.pdf');
            tempLink.click();*/
            //validando el dispositivo en el que se esta viendo la aplicacion
            if (navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/OS/i)
            ) {
                tempLink.href = blobUrl;
                tempLink.setAttribute('download', filename);
                tempLink.click();
            } else {
                window.open(blobUrl);
            }
        }
    }

    React.useEffect(() => {
        if (props.eudgc) {
            const urlFotografia = `/get/f/${props.eudgc?.dui}/${props.eudgc?.dob}`
            // TODO catch errors and handle them du to possible server connection problems
            getFotografiaData(urlFotografia).then(response => {
                if (response.ok) {
                    setFotografia(response.img_url);
                }
            })
        }
    }, [props.eudgc])

    return (
        !(isInit && eudgc && qrIsReady) ?
            < div className="d-flex justify-content-center align-items-center" style={{ gap: '3rem' }}>
                <Spinner animation="border" as="div" variant="light" />
            </div> :
            <>
                <Fade appear={true} in={true}>
                    <Card id='data-card' className='p-3 rounded-lg'>
                        {/*    content area with patient inputs and check box    */}
                        <Card.Header id='data-header' className='p-3'>
                            <Row>
                                <Col sm='6' className='pl-3'>
                                    <Card.Title className='m-md-0 tac-xs-tal-md jcc-xs-jcfs-md'
                                        as={'h3'}>{t('translation:your-certificate')}</Card.Title>
                                </Col>
                                {isMovile ? (
                                    <Col sm='6' className='pl-3' style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        {
                                            !fotografia ? (
                                                <img
                                                    id='img-persona'
                                                    src={fotografia}
                                                    alt="" />
                                            ) : <></>
                                        }
                                    </Col>
                                ) : (<></>)}
                            </Row>
                        </Card.Header>
                        <Card.Body id='data-body'>
                            <Row>
                                <Col sm='6' className='p-3'>
                                    <ShowCertificateData eudgc={eudgc} valueSetList={context.valueSets} />
                                </Col>
                                <Col sm='6' className='p-3'>
                                    <Container>
                                        <Row>
                                            {isMovile ? (<></>) :
                                                (
                                                    <Col style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <img
                                                            id='img-persona'
                                                            src={fotografia}
                                                            alt="" />
                                                    </Col>
                                                )}
                                        </Row>
                                    </Container>
                                    {/* <Card.Text className='input-label jcc-xs-sm m-3 text-center'>TAN: {tan}</Card.Text> */}
                                    <Container id='qr-code-container' className='squared-medium'>
                                        {qrIsReady ? <><QRCode id='qr-code' size={256} renderAs='svg'
                                            value={"https://certificado.vacunacioncovid19.gob.sv" + urlCertificateData} />
                                            {/* <Card.Text className='input-label' >{qrCodeForPDF}</Card.Text> */}
                                        </> : <></>}
                                    </Container>
                                    <Container id='qr-code-container' className='hidden'>
                                        {qrIsReady ? <><QRCode id='qr-code-pdf2' size={256} renderAs='canvas'
                                            value={"https://certificado.vacunacioncovid19.gob.sv" + urlCertificateData} />
                                            {/* <Card.Text className='input-label' >{qrCodeValue}</Card.Text> */}
                                        </> : <></>}
                                    </Container>
                                </Col>
                            </Row>
                        </Card.Body>

                        {/*    footer with correction and finish button    */}
                        <Card.Footer id='data-footer'>
                            <Row className='row-cols-2'>
                                <Col xs='12' sm='12' md='6' className='p-3 col'>
                                    <Button
                                        className='w-75 mx-auto button-generar mb-3'
                                        block
                                        onClick={handleShowPdf2}
                                    >
                                        {t('translation:generate-pdf-2')}
                                    </Button>
                                </Col>
                                <Col xs='12' sm='12' md='6' className='p-3 col'>
                                    {
                                        osCurrent ? (
                                            <></>
                                        ) : (
                                            <Button
                                                className='w-75 mx-auto button-generar mb-3'
                                                block
                                                onClick={handleDonwloadQr}
                                            >
                                                Descargar Código QR
                                            </Button>
                                        )
                                    }
                                </Col>
                                {/* <Col className='p-3 col'>
                                    <Button
                                        className='w-75 mx-auto button-generar mb-3'
                                        block
                                        onClick={handleShowPdf2}
                                    >
                                        {t('translation:generate-pdf-2')}
                                    </Button>
                                </Col> */}
                            </Row>
                        </Card.Footer>

                        <Row style={{ textAlign: 'center' }}>
                            <Col xs='12' md='12' className='pl-md-0 pr-md-2 pb-3 pb-md-0'>
                                <Container>
                                    {/* <img src={logo_goes_negro}  /> */}
                                </Container>
                            </Col>
                        </Row>
                    </Card>
                </Fade>
            </>

    )
}

export default ShowCertificate;