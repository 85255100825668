/*
 * eu-digital-green-certificates/ dgca-issuance-web
 *
 * (C) 2021, T-Systems International GmbH
 *
 * Deutsche Telekom AG and all other contributors /
 * copyright owners license this file to you under the Apache
 * License, Version 2.0 (the "License"); you may not use this
 * file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { Image, Container } from 'react-bootstrap'

import '../i18n';

import svLogo from '../assets/images/logo_certificado.svg'
let div_class = 'd-flex flex-column align-items-center'
if (window.location.pathname === '/record/show') {
    div_class = 'd-flex align-items-center'
}

const Header = (props: any) => {


    const [isInit] = React.useState(true);

    return (!isInit ? <></> :
        <>
            <Container className='d-flex justify-content-center pt-5'>
                <div className={div_class}>
                    <figure className=''>
                        <Image src={svLogo} className='m-3' />
                    </figure>
                    {/*<p className="text-white title-vacuna text-center">Certificado de Vacunación Europeo</p>*/}
                </div>
            </Container>
        </>
    )
}

export default Header;