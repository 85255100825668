import React from "react";
import '../../assets/css/modal.css'

import { Card, Col, Row } from "react-bootstrap";

//enviando el compónente de modal
const ModalComponent = ({ text, setShow }: any) => {
    return (
        <div className="backdrop">
            <Card style={{ width: '18rem', borderRadius: "15px" }}>
                <Row>
                    <Col></Col>
                    <Col className="col-auto">
                        <button className="close-button" onClick={() => { setShow(false) }}>
                            X
                        </button>
                    </Col>
                </Row>
                <Card.Body>
                    <Card.Text>
                        {text}
                    </Card.Text>

                </Card.Body>
            </Card>
        </div>
    )
}

export default ModalComponent;