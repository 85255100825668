/*
 * eu-digital-green-certificates/ dgca-issuance-web
 *
 * (C) 2021, T-Systems International GmbH
 *
 * Deutsche Telekom AG and all other contributors /
 * copyright owners license this file to you under the Apache
 * License, Version 2.0 (the "License"); you may not use this
 * file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { Route } from 'react-router-dom'
import { Container, Spinner } from 'react-bootstrap'

import './i18n';
import { useTranslation } from 'react-i18next';

import useNavigation from './misc/useNavigation';

import Footer from './components/footer.component';
import LandingPage from './components/landing-page.component';

import IError from './misc/error';
import ErrorPage from './components/error-page.component';
import ShowCertificate from './components/show-certificate.component';
import ShowCertificateData from './components/show-certificate.component-clone';
import ShowCertifcateComponent from './components/show-certificate-info';
import { EUDCC1 } from './generated-files/dgc-combined-schema';
import Header from './components/header.component';
import DataprivacyPage from './components/dataprivacy.component';
import ImprintPage from './components/imprint.component';
import AppContext, { IAppContext } from './misc/appContext';
import utils from './misc/utils';

const Routing = () => {
    const { t } = useTranslation();

    const [eudgc, setEudgc] = React.useState<EUDCC1>();
    const [certificado, setCertificado] = React.useState<any>()
    const [error, setError] = React.useState<IError>();
    const [errorShow, setErrorShow] = React.useState(false);
    const [dataPrivacyShow, setDataPrivacyShow] = React.useState(false);
    const [imprintShow, setImprintShow] = React.useState(false);

    const context: IAppContext = {
        navigation: useNavigation(),
        // valueSets: useGetValueSets(undefined, (msg) => { setError({ message: msg }) }),
        utils: utils
    }

    document.title = t('translation:title');

    React.useEffect(() => {
        if (error) {
            setErrorShow(true);
        }
    }, [error])

    React.useEffect(() => {
        if (!errorShow) {
            setError(undefined);
        }
    }, [errorShow])

    // React.useEffect(() => {
    //     if (context.valueSets && Object.entries(context.valueSets).length > 0 && context.navigation)
    //         setIsInit(true);
    // }, [context.navigation, context.valueSets])


    return (!(context.navigation) ?
        < div className="d-flex justify-content-center align-items-center" style={{ gap: '3rem' }}>
            <Spinner animation="border" as="div" variant="light" />
        </div> :
        <>
            <AppContext.Provider value={context}>
                {/*
    header, every time shown. fit its children
    */}
                <Route path={context.navigation.routes.root}>
                    {
                        window.location.pathname.includes("/record/show/info") ? (<></>) : (<Header />)
                    }
                    <ErrorPage error={error} show={errorShow} onCancel={error?.onCancel ? error?.onCancel : context.navigation.toLanding} onHide={() => setErrorShow(false)} />
                    <DataprivacyPage show={dataPrivacyShow} setShow={setDataPrivacyShow} />
                    <ImprintPage show={imprintShow} setShow={setImprintShow} />
                </Route>

                {/*
    Content area. fit the rest of screen and children
    */}
                <Container id='qt-body' className="content-w mx-auto px-0">

                    {/* Landing */}
                    <Route
                        exact
                        path={context.navigation.routes.landing}
                    >
                        <LandingPage setCertificado={setCertificado} setEudgc={setEudgc} />
                    </Route>


                </Container>
                <Container className="">
                    <Route
                        exact
                        path={`${context.navigation.routes.showCert}`}
                        render={(props => <ShowCertificate setCertificado={setCertificado} certificado={certificado} setEudgc={setEudgc} eudgc={eudgc} setError={setError} />)}
                    >
                    </Route>
                </Container>

                <Container>
                    <Route
                        exact
                        path={`${context.navigation.routes.showCertClone}`}
                        render={(props => <ShowCertificateData />)}
                    >
                    </Route>
                </Container>
                <Container>
                    <Route
                        exact
                        path={`${context.navigation.routes.showInfoData}`}
                        render={(props => <ShowCertifcateComponent />)}
                    >
                    </Route>
                </Container>

                {/*
    footer, every time shown. fit its children
    */}
                <Route path={context.navigation.routes.root}>
                    <Footer setDataPrivacyShow={setDataPrivacyShow} setImprintShow={setImprintShow} />
                </Route>
            </AppContext.Provider>
        </>
    )
}

export default Routing;