/*
 * eu-digital-green-certificates/ dgca-issuance-web
 *
 * (C) 2021, T-Systems International GmbH
 *
 * Deutsche Telekom AG and all other contributors /
 * copyright owners license this file to you under the Apache
 * License, Version 2.0 (the "License"); you may not use this
 * file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { Container, Image} from 'react-bootstrap'

import '../i18n';
import logoGob from "../assets/images/gobiero_salvador.svg";
import React from "react";

const Footer = (props: any) => {

    return (
        // simple footer with imprint and data privacy --> links tbd
        <Container className='d-flex px-0'>
           <figure className="mx-auto">
               <Image src={logoGob} className='m-3' />
           </figure>
        </Container>
    )
}

export default Footer;