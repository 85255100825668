import axios from "axios";

// const APIURL = 'https://api-validador.covid19.gob.sv';
const APIURL = 'https://api-v2.vacunacioncovid19.gob.sv/api/v1';
const minsalApi = axios.create({
    baseURL: APIURL,
    headers: {
        "Content-Type": "application/json"
    },
})

export const getInfoData = async (dui = '', date = '') => {
    try {
        const response = await minsalApi.get(`/info-certificado?identificador_persona=${dui}&fecha_nac=${date}`);

        return {
            ok: true,
            persona: response?.data?.persona,
            certificado: response?.data?.certificado,
        };
    } catch (error) {
        return {
            ok: false,
            errorMessage: error?.response?.data?.message,
        }
    }
}

const fotografiaURL = 'https://api-v2.vacunacioncovid19.gob.sv';

const fotografiaApi = axios.create({
    baseURL: fotografiaURL,
})

export const getFotografiaData = async (urlFotografia) => {
    try {
        const response = await fotografiaApi.get(urlFotografia);

        return {
            ok: true,
            img_url: response?.data?.datos?.foto?.img_url,
        };
    } catch (error) {
        return {
            ok: false,
            errorMessage: error?.response?.data?.message,
        }
    }
}