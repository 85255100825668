import React from "react";
import i18n from 'i18next'

import { Button, Card, Col, Container, Fade, Row, Spinner, Form, CardImg } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import axios from "axios";
import genEDGCQR, { CertResult } from '../misc/edgcQRGenerator';
import { EUDCC1 } from '../generated-files/dgc-combined-schema';
import { IValueSetList } from '../misc/useValueSet';
import moment from "moment";

import gob_logo from '../assets/images/gob-logo.png';
import gob_logo_black from '../assets/images/Logo_GOES_horizontal.png'
import logo from '../assets/images/Escudo_GOES.png';
import flag_el_salvador from '../assets/images/Bandera_SV.png';
import logo_covid_19 from '../assets/images/Logo_Vacuna_Covid.png';

const ShowCertifcateComponent = (props: any) => {
    const [qrCodeForPDF, setQrCodeForPDF] = React.useState<any>();
    const [eudgcForPDF, setEudgcForPDF] = React.useState<EUDCC1>();
    const [valueSetsForPDF, setValueSetsForPDF] = React.useState<IValueSetList>();
    const [issuerCountryCodeForPDF, setIssuerCountryCodeForPDF] = React.useState('');
    const [certificado, setCertificado] = React.useState<any>();
    const [eudgc, setEudgc] = React.useState<EUDCC1 | undefined>();
    const [qrCodeValue, setQrCodeValue] = React.useState('');
    const [tan, setTAN] = React.useState('');
    const [pdfIsInit, setPdfIsInit] = React.useState(false)
    const [pdfIsReady, setPdfIsReady] = React.useState(false)
    const [error, setError] = React.useState(false)

    //tomando los parametros
    let { dui, fecha_nac }: any = useParams();
    const fechaMoment = moment(fecha_nac);
    fechaMoment.format('DD/MM/YYYY');

    //const APIURL = process.env.REACT_APP_API_VALIDADOR;
    const APIURL = 'https://api-validador.covid19.gob.sv';
    const minsalApi = axios.create({
        baseURL: APIURL,
        headers: {
            "Content-Type": "application/json"
        },
    })

    React.useEffect(() => {
        getInfo()
    }, [dui, fecha_nac])

    const getInfo = async () => {
        const response = await minsalApi.get(`/api/v1/info-certificado?identificador_persona=${dui}&fecha_nac=${moment(fechaMoment).format('L')}`).then((response) => {
            return response;
        }).catch((error) => {
            // Error
            if (error.response) {
                if (error.response.status === 404) {
                    setError(true)
                    // setMessage('Información incorrecta');
                } else {
                    console.log(error.response);
                }
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            return error;
        });
        genEDGCQR(response.data.persona, response.data.certificado)
            .then((certResult: CertResult) => {
                setQrCodeValue(certResult.qrCode);
                setTAN(certResult.tan);
                setIssuerCountryCodeForPDF(certResult.issuerCountryCode);
                setQrCodeForPDF(document.getElementById('qr-code-pdf'));
                setEudgcForPDF(eudgc);
                setPdfIsReady(true)
            })
            .catch(error => {
            });
        setEudgc(response.data.persona)
        setCertificado(response.data.certificado)
    }

    //muestra las vacunas aplicadas

    //retornando la info
    return (
        (eudgc) ? (
            <DataPdfCertificate eudgc={eudgc} />
        ) :
            (
                error ? (<></>) :
                    (< div className="d-flex justify-content-center align-items-center" style={{ gap: '3rem' }}>
                        <Spinner animation="border" as="div" variant="light" />
                    </div>)
            )
    )
}

const DataPdfCertificate = (props: any) => {
    const eudgc = props.eudgc

    //retornando lo que se renderizara en pantalla
    return (
        <>
            <Container className="container-certificate">
                {/*Parte de arriba del pdfo*/}
                <Row className="row-cols-2">
                    <Col>
                        <h1>asdas</h1>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                {/*Parte de abajo del pdfo*/}
                <Row className="row-cols-2">
                    <Col>
                        <div className="container-qr">
                            <p>texto</p>
                        </div>
                        <img src={gob_logo_black} />
                    </Col>
                    <Col>
                        <div className="container-info-persona">
                            <img src={logo} />
                            <p>EU digital COVID certificate<br />
                                Certificat numérique européen COVID<br />
                                Certificado COVID digital de la UE</p>

                            <div className="container-textura-name">
                                <div className="container-info-logos">
                                    <img src={logo_covid_19} style={{width: "84px", height: "27px"}}/>
                                    <img src={flag_el_salvador} style={{width: "48px", height: "27px"}}/>
                                </div>
                                <div className="container-info-name">
                                    <p>Carlos Josue Hernandez Funes</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

function CertificateData(props: any) {
    const eudgc = props.eudgc

    return (
        <>
            <Container className="container-certificate">
                <Card className="child-container-certificate">
                    <Row className='justify-content-center text-center'>
                        <CardImg variant="top" src={gob_logo} className='goblogo' />

                        <p className="titulo-principal">CERTIFICADO DIGITAL<br />
                            DE VACUNACION COVID-19</p>
                        <p className="titulo-secundario">DIGITAL COVID-19 <br />
                            VACCINATION CERTIFICATE</p>
                    </Row>
                    <Row className='row container-texto'>
                        <Col sm='6' className='p-3 texto-certificate'>
                            <Row><label className="titulo-certicficate">NOMBRE/NAME</label></Row>
                            <Row><label >{eudgc?.nam?.fnt}, {eudgc?.nam?.gnt}</label></Row>
                        </Col>
                    </Row>
                    <Row className='row container-texto'>
                        <Col sm='6' className='p-3 texto-certificate'>
                            <Row><label className="titulo-certicficate">FECHA DE NACIMIENTO/DATE OF BIRTH</label></Row>
                            <Row><label>{eudgc?.dob}</label></Row>
                        </Col>
                    </Row>
                    <Row className='row'>
                        <Col sm='6' className='p-3 texto-certificate'>
                            <Row><label className="titulo-certicficate">NUMERO DE IDENTIFICACION PERSONAL/ID NUMBER</label></Row>
                            <Row><label>{eudgc?.dui}</label></Row>
                        </Col>
                    </Row>
                    <Row className='row'>
                        <Col sm='6' className='p-3 texto-certificate'>
                            {
                                eudgc?.vaccines.map((item: any) => (
                                    <>
                                        <Row> <label className="titulo-certicficate">DOSIS {item.dosis_number} /DOSE {item.dosis_number}</label></Row>
                                        <Row> <label >{item.product}</label></Row>
                                        <Row> <label >{item.provider}</label></Row>
                                        <Row> <label >{item.datetime.substring(0, 10)}</label></Row>
                                    </>
                                ))
                            }
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}

export default ShowCertifcateComponent;